/*-------------------------------CardStatistics----------------------------------------*/
.card-statistics {
	box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.card-statistics .ant-card-body {
	padding-top: 6px !important;
}

.card-statistics .card-header{
	min-height: 80px;
}
.card-statistics .card-title {
	font-size: 1.5rem;
	color: #A0AEC0;
	margin-bottom: 0px !important;
}

.card-statistics .card-content {
	margin: 24px 0px -5px 0px;
}

.card-statistics .card-monto {
	font-family: Poppins;
	font-size: 24px;
	font-weight: 500;
	line-height: 34px;
	letter-spacing: 0em;
	text-align: left;
	color: #D12B00B2;

}

.card-statistics .card-suffix {
	margin-left: 5px;
	font-weight: bold;
	font-size: 19px;
	text-transform: uppercase;
	color: #D12B00B2;
}

.card-statistics .icon-container {
	border-radius: 20px;
	width: 58px;
	height: 58px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-statistics .icon-container>* {
	font-size: 28px;
	width: 28px;
	height: 28px;
}

.card-statistics .card-percent .card-monto {
	font-size: 14px;
	font-weight: normal;
}

.card-statistics .card-percent.green * {
	color: #00CD98;
}

.card-statistics .card-percent.red * {
	color: #FF4D4F;
}

.card-statistics .card-icon {
	display: flex;
	align-content: center;
}

.card-statistics .card-icon>* {
	font-size: 16px;
	width: 16px;
	height: 16px;
}

.card-statistics-small .card-title {
	font-weight: 500;
	font-size: 14px;
	color: #ABBBC2;
}

.card-statistics-small .card-monto {
	font-weight: 600;
	font-size: 28px;
	color: #16B1C2;
	margin-bottom: 8px;
}

.card-statistics-small .icon-container {
	background: #16B1C2;
	box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
	border-radius: 12px;
	width: 38px;
	height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
}



/*
 * @component CardSimpleInfo
 * @description Estilos para el CardSimpleInfo
 */
.card-simple .title-simple {
	font-size: 14px;
	font-weight: 400;
	color: #8a8888;
}

.card-simple .info-simple {
	font-size: 30px;
	font-weight: 500;
	margin-top: 0.5rem;
}


@media screen and (max-width: 1024px) {
	.card-statistics-small .card-monto {
		font-size: 18px !important;
	}

	.card-statistics-small .card-title {
		font-size: 10px !important;
	}

}
@media screen and (max-width:768px){
	.card-statistics .card-title{
		font-size: 1.2rem;
	}
}