.direcciones{
    &-add{
        width: 100%;
        height: 320px;
        background-color: rgba(#8A8A8A, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 10px;
        cursor: pointer;
    }

}