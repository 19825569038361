/*
 * @component Header 
 * @description Cambia el tamaño del header
 */
.header-public {
	z-index: 1000;
	background-color: transparent !important;
	width: 100%;
	font-weight: bold;
	padding: 0;
	height: unset !important;

	transition: all 0.3s ease-in-out;

	position: fixed;
	top: 0;
	border-bottom: 1px solid #446cb2;

	&.is-scrolling {
		// padding: 0px 12px;
		box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .10) !important;
		background: white !important;
		border-bottom: none;

		.ant-menu-title-content {
			color: #171717 !important;
		}

		.ant-menu-item-selected {
			//background: rgba(124, 130, 202, 0.20);
		}

		.general-ribbon {
			height: 0px !important;
		}
	}

	&-container {
		width: 100%;
		justify-content: space-between;
		max-width: 1400px;
		margin: 0 auto;
	}

	.general-ribbon {
		background-color: black;
		transition: all 0.3s ease-in-out;
		width: 100%;
		justify-content: space-between;
		min-height: 30px;

		.ribbon {
			color: white;
			text-align: center;
			width: 100%;
			position: relative;
			top: 6px;
			// style={{  color: 'white', textAlign: "center" }}
		}
	}

	&-menu {
		background-color: transparent !important;
		flex: 1;

		&-logo {
			//width: 55%;

			.ant-menu-title-content {
				display: flex;
				align-items: center;
				/* Centrado vertical */
				height: 100%;
			}

			&.ant-menu-item-selected {
				background-color: transparent !important;
			}
		}

		&-item {
			background: none !important;
			padding: 0;
			margin: 0;
			flex: 1 !important;
			text-align: right;

			&::after {
		    border: none !important;
		  }
		}

		&-item:last-child {
			padding: 0 8px !important;
			margin: 0;
		}

		

	}
}

.badge-cart {
	.ant-scroll-number {
		background-color: white;
		border: none;
		color: #454444;
	}

	&.scrolled {
		.ant-scroll-number {
			border: solid 1px #454444;
			height: 23px;
			width: 23px;
			border-radius: 50px
		}

	}
}

.user-menu {
	line-height: 25px;
}

.menu-item-responsive-small{
	display: none !important;
}


@media(max-width: 881px) {


	.div-container {
		width: 100%;
		flex-direction: row;
		display: flex;
		align-content: center;
		justify-content: center;
		line-height: 64px;
	}

	.space-menu {
		display: flex !important;
		flex-direction: column;
		align-items: flex-start !important;
		justify-content: flex-start;
	}

	.menu-item-mobile,
	.header-public-menu-mobile {
		display: block !important;
		visibility: visible !important;
	}

	.menu-item-desktop,
	.header-public-desktop,
	.header-public-menu-desktop {
		display: none !important;
		visibility: hidden !important;
	}
	
}

@media(min-width: 881px) {

	.menu-item-desktop,
	.header-public-desktop,
	.header-public-menu-desktop {
		display: block !important;
		visibility: visible !important;
	}

	.div-container {
		width: 100%;
		flex-direction: row;
		display: flex;
		align-content: center;
		justify-content: center;
		line-height: 64px;
	}

}


@media(max-width: 918px) {
	.header-public .ant-dropdown-trigger {
		display: none;
	}
}

@media(max-width: 992px) {

	.header-public-menu-item{
		text-align: left;
	}

	.menu-item-responsive{
		min-height: 52px;
		display: none !important;
		font-weight: bold;
	}

	.header-public-menu-logo{
		min-height: 52px !important;
	}

	.menu-item-responsive-small{
		display: inline !important;
		width: 55%;
		text-align: right;

		&::after {
	    border: none !important;
	  }

	  &.ant-menu-item-selected{
	  	background-color: white !important;
	  }
	}

	.menu-vertical{
		border-inline-end: none !important;
	}
}

@media(max-width: 590px) {
	.header-public {
		flex-direction: column;
		height: unset;
	}
}

.header-admin {
	padding: 0 !important;
	height: unset !important;
	background: white;
}

.header-admin.blue{
  background: #426cb4;
}

.header-admin .logo-header {
	width: 100%;
	max-width: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	background: rgba($color: #426CB4, $alpha: 0.85);
}

.header-admin .logo-header img {
	width: 70%;

}

.header-admin .header-content {
	padding-top: 0.6rem;
}

.header-admin .ant-input-group-wrapper {
	max-width: 500px;
	margin-left: 1rem;
}

.notifications-list {
	width: 280px;
}

.notification-element {
	height: 70px !important;
}

.notification-element .ant-menu-title-content .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-detail,
.notification-element .ant-menu-title-content .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-actions {
	margin: 0px;
	padding: 0px;
	line-height: 15px;
}

@media(max-width:835px) {

	/* .header-admin .width-100{
		padding-top: 10px;
	} */
	.header-admin .logo-header {
		display: none;
	}
}

@media screen and (max-width:768px) {
	.header-public-menu-item{
		flex: 0;

		button{
			width: none
		}
	}
}