.clientes{

    & > .ant-row{
        margin: 0px !important;
    }

    &-collapse{
        display: flex !important;
        flex-direction: column !important;
        row-gap: 12px;
        background-color: transparent !important;
    }

    &-tab{
        border-bottom: none !important;

        .btn_cargar{
            margin-top: 20px !important;

        }

        & .ant-collapse-content-box{
            padding: 4px 0 16px 0 !important;
            text-align: center;
        }

        & > .ant-collapse-header{
            width: 300px;
            display: inline-flex;
            border-radius: 10px !important;

            // & * {
            //     color: white;
            // }
        }
        
        &.ant-collapse-item-active > .ant-collapse-header{
            border-radius: 10px 10px 0 0 !important;
        }
        /*tabs de ventas*/
        &.tab-pendiente > .ant-collapse-header{
            background-color: #94979c;
            color: white;
        }
        &.tab-recibido > .ant-collapse-header{
            background-color: #c5e1c7;
          
        }
        &.tab-lavado > .ant-collapse-header{
            background-color: #5465FF;
            color: white;
        }
        &.tab-detallado > .ant-collapse-header{
            background-color: #F2802E;
            color: white;
        }
        &.tab-empaquetado > .ant-collapse-header{
            background-color: #8d6e63;
            color: white;
        }
        &.tab-entregado > .ant-collapse-header{
            background-color: #94979c;
            color: white;
        }
        &.tab-listo > .ant-collapse-header{
            background-color: #e33814;
            color: white;
        }

        /*tabs de clientes*/
        &.tab-cancelados > .ant-collapse-header{
            background-color: #FF4D4F;
            width: 250px
        }
        &.tab-registrados > .ant-collapse-header{
            background-color: #FFE608;
            width: 250px;
        }
        &.tab-contactado > .ant-collapse-header{
            background-color: #5465FF;
            width: 250px;
        }
        &.tab-comprado > .ant-collapse-header{
            background-color: #F2802E;
            width: 250px;
        }
        &.tab-pagado > .ant-collapse-header{
            background-color: #00CD98;
            width: 250px;
        }
    }

    &-tabname{
        color: white !important;
    }

    &-list.component-list .component-list-item{
        padding: 2px 0 !important;

        &:first-child{
            padding-top: 0px !important;
        }
    }

    &-circle{
        width: 16px;
        height: 16px;
        border-radius: 10px;

        &.small{
            width: 10px;
            height: 10px;
        }

        &.circle-1{
            background-color: #FF4D4F;
        }
        &.circle-2{
            background-color: #FFE608;
        }
        &.circle-3{
            background-color: #5465FF;
        }
        &.circle-4{
            background-color: #F2802E;
        }
        &.circle-5{
            background-color: #00CD98;
        }
    }

    &-dropdown{
        cursor: pointer !important;
    }

    &-select{
        & .ant-select-selection-item, & .ant-select-selection-item > .ant-space, & .ant-select-selection-item > .ant-space > .ant-space-item{
            line-height: 0px !important;
        }
    }
}

.card-direccion{
    height: 320px;

    &.normal-direccion{

        .ant-card-body *{
            font-size: 16px
        }
    }

    &.new-direccion{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        background-color: rgba(138, 138, 138, 0.15);
    }

    &:hover{
        background-color: #eaeaea;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }
}