@font-face {
    font-family: 'Satoshi';
    src: url('Satoshi-BlackItalic.woff2') format('woff2'),
        url('Satoshi-BlackItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('Satoshi-Black.woff2') format('woff2'),
        url('Satoshi-Black.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'false';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'false';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('Satoshi-Italic.woff2') format('woff2'),
        url('Satoshi-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('Satoshi-Light.woff2') format('woff2'),
        url('Satoshi-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'false';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'false';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'false';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('Satoshi-Bold.woff2') format('woff2'),
        url('Satoshi-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'false';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('Satoshi-BoldItalic.woff2') format('woff2'),
        url('Satoshi-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'false';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'false';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('Satoshi-MediumItalic.woff2') format('woff2'),
        url('Satoshi-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('Satoshi-LightItalic.woff2') format('woff2'),
        url('Satoshi-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('Satoshi-Medium.woff2') format('woff2'),
        url('Satoshi-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('Satoshi-Regular.woff2') format('woff2'),
        url('Satoshi-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('false.woff2') format('woff2'),
        url('false.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

