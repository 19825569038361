@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800&family=Montserrat:wght@100;200;300;400;600;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&family=Roboto:ital,wght@0,100;0,300;0,400;0,900;1,100&family=Sen:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800&family=Luckiest+Guy&family=Montserrat:wght@100;200;300;400;600;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&family=Roboto:ital,wght@0,100;0,300;0,400;0,900;1,100&family=Sen:wght@400;700;800&display=swap');

/**
*
*estilos de todo lo que se encuentre en la landing page
*/
.landing {

    /*banner principal con imagen de fondo*/
    .content-bg-shoe {
        position: relative;
        //height: 100%;
        min-height: 100vh;
        overflow: hidden;
        padding-top: 4rem;
        background-color: rgb(255, 255, 255) !important;
    }

    .normal-text {

        text-transform: capitalize;
        text-align: center;
        font-family: "Luckiest Guy", cursive !important;
        white-space: pre-wrap;
        font-weight: 900;
            font-size: 2.7rem;
            color: #ffffff !important;


    }


    .video-banner {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 5%;

    }

    .video-text {
        position: relative;
        top: 10%;
    }


    /*banner principal texto */
    .banner-title {
        font-weight: 700;
        font-size: clamp(2.5rem, 5vw, 6rem);
        text-wrap: nowrap;
        text-transform: capitalize;
        color: #446cb2;
        margin-bottom: 0rem;
        padding: 2rem 2rem 0rem 2rem;
        word-wrap: break-word;
        text-shadow: 0 .5rem 1rem rgba(0, 0, 0, .10);
    }

    .banner-text {
        font-weight: 200;
        font-size: clamp(1rem, 5vw, 1.5rem);
        text-wrap: nowrap;
        color: #000;
        padding: 1rem 2rem 0rem 2rem;
        text-shadow: 0 .5rem 1rem rgba(0, 0, 0, .10);
    }

    .section-title {
        font-size: 2rem;
        color: #446cb2;
        margin-bottom: 1em;
        text-align: center;
        text-transform: uppercase;
        // font-family: 'Luckiest Guy', cursive !important;
        // letter-spacing: 2px;
    }

    .section-title-lucky {
        font-size: 3rem;
        color: #446cb2;
       // margin-bottom: 1em;
        text-align: justify;
        text-transform: capitalize;
        font-family: 'Roboto', cursive !important;
        letter-spacing: 0.5px;
    }

    /*seccionde How it works*/
    .section-how-work{

        .image-how-shoe{
            width: 100%;
        }

        .main-title-how{
            font-size: 52px;
            margin-top: 0px;
            margin-bottom: 1rem;
        }

        .main-text-how{
            font-size: 18px;
            margin-top: 0px;
            margin-bottom: 1rem;
            font-weight: bold;
        }

        .text-how{
            font-size: 16px;
            font-weight: bold;
            font-family: 'Roboto', cursive !important;
        }

        .divider-how{
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding: 0px;
            border-width: 2px;
        }

        .title-how{
            margin-top: 1rem;
            margin-bottom: 1rem;
            color: #446cb2 !important;
            font-family: 'Roboto', cursive !important;
        }

    }

    .title {
        font-weight: 900;
        font-size: 4rem;
        text-transform: uppercase;

        &.blue {
            color: #070e1b;
        }

        &.custom {
            font-family: 'Luckiest Guy', cursive !important;
        }
    }

    .white {
        color: #FFFFFF;
    }

    .section-description {
        text-align: justify;
        font-size: 1.35rem;
        color: #030303 !important;
        font-weight: 700;
    }

    /*banner principal imagen de relleno*/
    .banner-image {
        position: absolute;
        z-index: 4 !important;
        left: 70%;
        bottom: 50%;
    }

    .section-reduce {
        max-width: 1400px;
        color: rgba(0, 0, 0, 0.20) !important;

        &.white {
            color: white !important;
        }
    }

    .ant-divider {
        border-color: #ffffff; // Cambia el color del borde a blanco
        color: #ffffff;
        padding-top: 2rem;

        .ant-divider-inner-text {
            font-weight: 900;
            font-size: 4.375rem;
            color: #000000 !important;
            text-transform: uppercase;
            font-family: 'Luckiest Guy', cursive !important;
        }

        &.text-capitalize * {
            text-transform: capitalize !important;
        }

        &.no-border {
            border-color: transparent !important;
        }

        &.blue {
            border-color: #7C83CA;
        }

        &.normal-text {
            .ant-divider-inner-text {
                font-size: 3.5rem;
                font-family: 'Luckiest Guy', cursive !important;
                white-space: pre-wrap;

            }
        }
    }

    //separar sections
    .section-space {
        margin-top: 60px;
        padding-bottom: 120px;
    }



    /*
    *estilos de las secciones de la landing
    */
    .section {
        margin: auto;
        margin-bottom: 5%;
    }

    .section {

        .title {
            margin:auto;
            font-weight: 700;
            font-size: 4rem;
            text-transform: capitalize;
            text-align: center;
            padding:1rem;
            //max-width: 50% !important;
        }
    }

    .section-container {
        margin: 1rem;
        padding: 4rem;
        border-style: solid;
        border-radius: 32px;
        border-color: #446cb2;
        border-width: 2px;


        .text-free{
           // font-weight: 500;
            font-size: 2rem;
            color:#000;
            margin-bottom: 1em;
            text-align: left;
            font-family: 'Roboto', cursive !important;
            letter-spacing: 0.5px;
            padding-right: 10%;
            padding-left: 5rem;
            max-width: 80%;
        }
    }

    .section-container.services{
        border-color: #FFFFFF !important;
    }

    .section-productos {
        background: rgba($color: #181717, $alpha: 0.9);
    }

    .button {
        background: #181717;
    }

    .button-icon {
        width: 45px;
        background: none;

        svg {
            color: #181717;
            font-size: 20px;
        }
    }

    .circle {
        background-image: url(http://localhost:3000/static/media/circle.97ade9f….png);
    }


    .footer {
        background: rgba($color: #446cb2, $alpha: 1);

        min-height: 30vh;
        max-height: auto;

        * {
            color: #E7E7E9;
            font-family: 'Roboto', sans-serif;

        }

        .title {
            text-transform: uppercase;
            font-size: 1.5rem;
        }

        .text {
            font-size: 1.225rem;
        }

        &-container {
            max-width: 1400px;
            margin: 0 auto;
            padding: 1rem;
        }

        .footer-logo {
            height: 50px;
            display: block;
            margin-top: 20px;
            margin-bottom: 20px;

        }

        .mapa {
            max-width: 400px;
            max-height: 300px;
            min-width: 200px;
            width: 100%;
            min-height: 200px;
            border: none;
        }
    }


    .card {
        width: 400px;
        margin: auto !important;
        background: none !important;

        .rotate {
            transform: rotateY(180deg)
        }

    }


    .card-shoe {

        .ant-card-body {
            padding: 0;
            
        }

        .img-title {
            font-size: 1.75rem !important;
            text-transform: capitalize;
            color: #ffffff;
            font-weight: 600;
            display: block;
            text-align: center;
        }
    }

    .img-6 {
        max-width: 250px;
        height: 250px;
        object-fit: contain;
        display: block;
    }

    .img-middle {
        max-width: 650px;
        width: 100%;
        position: absolute;
        bottom: 0;
        //object-fit: contain;
        //display: flex;
        //align-self: center;
        //justify-content: center;
        //margin: auto;
    }

    .img-large {
        min-width: 80%;
        min-height: 40%;
        object-fit: fill;
        display: block;
        align-self: center;
        justify-content: center;
        margin: auto;
        margin-bottom: 20%;
        position: absolute;
        bottom: 0;
    }


    .width-80 {
        width: 40%;

    }

    .width-90 {
        width: 70%;

    }

    .img-left {
        margin-top: 30% !important;
        position: relative !important;
        left: -10% !important;
        overflow: hidden;
    }

    .img-top {
        margin-top: -70px !important;

    }

    .WidgetBackground__Content-sc-1ho7q3r-2.ciCnpO>a
                     {
                        display: none !important;
                        visibility: hidden !important;
                    }
             
    

}

.menu-login {
    color: #fff;
    display: block;
    height: 50px;
    width: 200px;

    &:hover {
        color: #426CB4 !important;
    }
}

.hover:hover {
    color: #426CB4 !important;
    animation-delay: 1s;
}

.menu-logo {
    height: 45px;
}

/**
*estilos del banner de promociones
*
*/
.hot-banner {

    //position: absolute;
    width: 80%;
    border-radius: 0 !important; 
    display: block;
    margin: 0 auto;
    border: none;

    .hs-hot {
        background: #181717;
        width: 25%;
    }

    .hs-grid {

        height: 100px;

        &.prod-1 {
            width: 75%
        }

        &.prod-2 {
            width: 37.5%
        }

        &.prod-3 {
            width: 25%
        }



        .hs-big-text * {
            font-size: 1.25rem;
            text-transform: uppercase;
            color: #fff;
            font-weight: 600;

        }

        .hs-title {
            font-size: 1rem;
            color: #222222;

        }

        .hs-amount {
            font-size: 1rem;
            color: #9C9C9C;
            font-weight: 500;

        }
    }
}

/**
*estilo del cotizador
**/
.bg-left-modal {
    background-image: url('../../../public/img/img-1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    height: 300px;
}

/*estilos de los cards de servicios*/
.card-services {
    width: 300px;
    min-width: 300px;
    height: 700px;
    background: #446cb2;
    margin: auto;

    .ant-card-head {
        text-align: center;
        text-transform: uppercase;
        color: #E7E7E9;
    }

    .ant-card-cover {
        max-width: 250px;
        min-height: 180px;
        max-height: 200px;
        display: flex;
        justify-self: center;
        justify-items: center;
        justify-content: center;
        margin: auto;

        .img-cover {
            aspect-ratio: 4 / 3;
            overflow-y: hidden;
            object-fit: contain;
            border-radius: 0;


        }


    }

    .ant-card-body {
        .ant-typography {
            min-height: 50px;
        }
    }

    .amount {
        font-size: 1.75rem;
        color: #ffffff;
        font-weight: 700;
        text-align: center;
        margin:auto !important;
    }

    .title-card{
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.75rem;
        color: #ffffff;
        font-weight: 700;
        text-align: center;
    }

    .divider-card{
        margin-top: 0;
        margin-bottom: 0;
        padding: 10px;
    }

    .descripcion{
        max-width: 100%;
        margin:auto !important;
        font-size: 1rem;
        text-align: center;

    }

    .choose-btn{
        width: 100%;
        margin:auto;
        margin-bottom: 10%;
        text-align: center;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.card-productos {

    background: rgba($color: rgb(255, 255, 255), $alpha: 0.3);
    max-width: 300px;
    border: solid 1px rgba(217, 217, 217, 0.3) !important;
    box-shadow: 0px 0px 10px rgba(217, 217, 217, 0.3);

    .ant-card-head {
        text-align: center;
        text-transform: uppercase;
        color: #426CB4;
        overflow: hidden;

        .card-productos-title {
            color: #426CB4;
            width: 200px;
            -webkit-line-clamp: 1;
        }

    }

    .ant-card-cover {
        display: flex;
        justify-self: center;
        justify-items: center;
        justify-content: center;
        background: #426CB4;
        margin: 0 1rem;

        .img-cover {
            aspect-ratio: 3 / 2;
            overflow-y: hidden;
            object-fit: cover;
            border-radius: 0;
        }

    }

    .ant-card-body {
        .ant-typography {
            min-height: 66px;
        }
    }

    .amount {
        font-size: 1rem;
        color: #426CB4;
        font-weight: 500;
    }


}

.card-process {
    width: 300px;
    height: 380px;
    text-align: center;

    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;

    .ant-card-meta {
        margin-top: 1rem;

        .ant-card-meta-detail {

            .ant-card-meta-title {
                height: 40px;
                line-height: 1.5rem;
                color: #426CB4;
                font-size: 1.5rem;
            }

            .ant-card-meta-description {
                font-size: 17px;
            }
        }
    }
}

.carousel-productos {
    width: 100%;
    max-width: 700px !important;
    max-height: 1080px;
    aspect-ratio: 1 / 2;
    object-fit: contain;

}



.radio-card-item {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, .075);
    margin-top: 10px;
    border-radius: 6px;
}


.bg-gradient-color {
    // background-image: linear-gradient(155deg,
    //         hsl(236deg 31% 64%) 0%,
    //         hsl(351deg 51% 70%) 20%,
    //         hsl(275deg 73% 82%) 40%,
    //         hsl(202deg 89% 92%) 60%,
    //         hsl(226deg 52% 89%) 80%,
    //         hsl(341deg 48% 67%) 100%);
    background-size: cover;
}

.bg-secondary {
    background: rgba($color: #9DBCDD, $alpha: 1) !important;
}

.bg-transparent {
    background: transparent !important;
}

.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.radio-group-address {
    .ant-radio-wrapper {
        span {
            &:not(.ant-radio) {
                width: 95%;
            }

            &.ant-radio {
                width: 16px;
            }
        }
    }
}


@media screen and (min-width:2000px) {
    .banner-image {
        top: -30%;
    }

}

@media screen and (max-width:1440px) {
    

    .card-process {
        height: 450px;

        .ant-card-meta {
            .ant-card-meta-detail {

                .ant-card-meta-description {
                    font-size: 15px;
                }
            }
        }

    }
}

@media screen and (max-width:1280px){
    .img-middle {
        max-width: 500px !important;
       
    }
}

@media screen and (max-width:1024px) {

    .section-container {
        padding: 2rem !important;

    }

    .landing .section-title-lucky {
        font-size: 3rem;
    }

    .banner-image {
        top: -50%;
    }

    .ant-divider {

        .ant-divider-inner-text {
            font-size: 3rem !important;
        }

        .title {
            font-size: 3rem !important;
        }

    }

    .img-top {
        margin-top: -17% !important;
        width: 100%;
    }

    .img-left {
        width: 100%;
    }

    .section-space {
        margin-top: 30px;
        padding-bottom: 60px;
    }

    .bg-left-modal {
        display: block;
    }
}

@media screen and (max-width:992px) {

    .img-middle {
        max-width: 300px !important;
        left: 50%;
        transform: translateX(-50%);
    }
    

    .hot-banner {

        .hs-hot {
            width: 50%
        }

        .hs-grid {

            min-height: 100px;

            &.prod-1 {
                width: 50% !important;
            }

            &.prod-2 {
                width: 50%
            }

            &.prod-3 {
                width: 50%
            }
        }
    }

    .ant-divider {
        white-space: pre-wrap !important;
        font-size: 1.75rem !important;

        .ant-divider-inner-text {
            padding: 0.25rem;
        }

        .section-title {
            font-size: 1.2rem !important;
            width: 300px;
            text-align: center;
            display: flex;
            justify-content: space-around;
        }
    }

    .landing .section-title-lucky {
        font-size: 1.75rem;
    }

    .bg-left-modal {
        display: none;
    }
}

@media screen and (max-width:768px) {

    .img-middle {
        max-width: 300px !important;
        left: 50%;
        transform: translateX(-50%);
    }

    .img-left {
        margin-top: 60%;
        left: -27% !important;
    }

    .width-80 {
        width: 50% !important;
    }

    .title {
        &.custom {
            font-size: 3.375rem;
            text-align: center;
            font-family: 'Luckiest Guy', cursive !important;
        }
    }
}

@media screen and (max-width:576px) {

    .img-middle {
        max-width: 300px !important;
        left: 50%;
        transform: translateX(-50%);
    }

    .menu-logo {
        height: 35px;
    }
}

/*xs*/
@media screen and (max-width:480px) {

    .img-middle {
        max-width: 300px !important;
        left: 50%;
        transform: translateX(-50%);
    }

    .section {
        padding: 0 !important;
    }

    .ant-divider {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;

        .ant-divider-inner-text {
            font-size: 2rem !important;
        }

        .title {
            font-size: 1.225rem !important;
        }
    }

    .section {

        .title {
            max-width: 90%;
            font-size: 2rem !important;
        }

    }

    .section-title {
        font-size: 1.225rem !important;
        display: flex;
    }

    .landing .section-title-lucky {
        font-size: 1.5rem;
    }

    .section-description {
        font-size: 1rem !important;
    }

    .card-services {
        max-width: 100% !important;
        min-width: 100% !important;

        .ant-card-cover {
            max-width: 200px;
            min-height: 180px;
            max-height: 200px;
        }
    }

    .img-6 {
        width: 180px !important;
    }

    .width-80 {
        width: 100% !important;
    }

    .menu-logo {
        height: 30px;
    }

    .banner-text,
    .banner-title {
        padding: 0 !important;
        
    }
}
