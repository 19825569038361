.echart-card{
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2 )!important;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 0px rgb(27 49 66 / 13%);
    border-radius: 10px;
    padding: 1rem;
}

.card-dashboard{
    min-height: 410px;
}
.echart-card > .ant-card-head {
    border: none;
    font-family: Roboto;
    font-size: 0.938rem;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.2em;
    text-align: center;
    color: #A3A3A3;

}
.card-dashboard-md{
    height: 492px;
}
.hm-echart-md{
    min-height: 400px;
}
.hm-echarts-8{
    height: 300px;
}
.card-cover-fixed{
    height: 250px!important;
    max-height: 250px!important;
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
 .card-saldo {
    border-radius: 10px !important;
    min-height: 219px;
}

/*
 * @component CardSaldo
 * @description el titulo del Card
 */
.card-saldo .card-saldo-main-meta .ant-card-meta-title {
    color: #858997;
    font-weight: 600;
    font-size: 1.375rem;
    margin-bottom: 1.2rem;
}

/*
 * @component CardSaldo
 * @description estilos para el monto total de las cuentas
 */
.card-saldo .card-saldo-main-meta .ant-card-meta-description {
    font-weight: bold;
    font-size: 30px;
    color: #000;
    margin-bottom: 15px;
}

/*
 * @component CardSaldo
 * @description estilos del small
 */
.card-saldo .card-saldo-main-meta small {
    font-size: 14px;
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo .card-saldo-sub-meta .ant-card-meta-title {
    font-weight: 600;
    font-size: 12px;
    color: #858997;
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo .card-saldo-sub-meta .ant-card-meta-description {
    font-weight: 600;
    font-size: 12px;
    color: #0047FF !important;
    margin-bottom: 20px;
}


/*
 * @component CardCuenta
 * @description Estilo para la tarjeta bancaria
 */
 .card-cuenta {
    border-radius: 20px !important;
    margin-right: 1rem !important;
    min-height: 219px;
    width: 370px;
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 5px !important;
}

.card-cuenta .btn-purple {
    background: rgba(169, 6, 245, 0.75);
    color: white;
    border: none;
}

/*
 * @component CardCuenta
 * @description Estilo para el nombre de la cuenta
 */
.card-cuenta .cuenta-nombre {
    font-size: 1.125rem;
    color: #FFFFFF;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo principal de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-title span.ant-typography{
    font-weight: bold;
    font-size: 1.875rem !important;
    color: #FFFFFF;
    margin-top: 0.9rem;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo secundario de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-description {
    font-weight: bold;
    font-size: 18px !important;
    color: #FFFFFF;
    margin-bottom: 0.5rem;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo secundario de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-title small {
    font-size: 14px;
    color: #FFFFFF;
}

/*
 * @component CardAddress
 * @description Estilo para el card de direcciones
 */
.card-direcciones{
    height: 320px;
}
.card-direcciones-description{
    margin-top: 5px;
}

@media (max-width:1024px){
    .echart-card > .ant-card-head{
        font-size: 0.7rem;
        letter-spacing: 0.1rem;
    }
   
    
}

@media (max-width:992px){
    .echart-card > .ant-card-head{
        font-size: 0.9rem;
        letter-spacing: 0.2rem;
    }
    
}


.card-quotation-step{
    .ant-card-body{
        padding: 7px;
    }

    .color{
        height: 98px;
        border-radius: 10px;
        margin-bottom: 14px;
    }

    .title{
        font-size: 13px;
        margin: 0px !important;
    }

    .description{
        font-size: 13px;
        margin: 0px !important;
        color: #979797;
    }
}

.card-quotation{
    padding: 16px 24px;

    .ant-card-head{
        padding: 0px;
    }

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3{
            margin: 0px !important;
            font-size: 16px;
        }

        .ant-tag{
            font-size: 12px;
            font-weight: 600;
            padding: 5px 10px;
        }
    }

    .ant-card-body{
        &::after, &::before{
            display: none;
        }

        display: flex;
        flex-direction: column;
        row-gap: 24px;
        padding: 24px 0 0 0;
    }

    .description{
        font-size: 13px;
        color: #5E5E7A;
        line-height: 20px;
    }

    .tags{
        display: flex;
        column-gap: 12px;
        row-gap: 12px;

        *{
            color: #7B61FF;
            font-weight: 600;
            font-size: 10px;
        }
    }

    .date{
        font-size: 10px;
        color: #2E2E4D;
    }
}